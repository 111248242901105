import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Modal,
  Card,
  ListGroup,
  Button,
} from "react-bootstrap";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

import styled from "styled-components";

import { getProfile, profileAction } from "../../../store/actions/AuthActions";
import Swal from "sweetalert2";
import { getAllProducts } from "../../../store/actions/licActions";
import StripeCheckout from "./StripeCheckout";

const stripePromise = loadStripe(
  "pk_test_51L1E9YSFDFHp5bEhFLrxuBRiZ0ifZQE5Nle0k1szQOzv3H3fOG0UXU2QsxbBzvGJYBDqsFN73f0P58hWVpFJYddC00qtpMYQRs"
);

//const Orders = () => {
const BuyLicenseKeys = ({ props, history }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  //const [paymentMethod, setPaymentMethod] = useState("razorpay"); //phonepe //razorpay //stripe //paypal
  const [orderCompleted, setOrderCompleted] = useState(false);

  const profile = useSelector((state) => state.auth.profile);
  const products = useSelector((state) => state.lic.products);

  const [currencyRates, setCurrencyRates] = useState({});

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(10);
  const [selectedValidity, setSelectedValidity] = useState(30);

  const [clientSecret, setClientSecret] = useState("");

  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    dispatch(getAllProducts());
    loadCurrencyRates();
  }, [dispatch]);

  useEffect(() => {
    
    if(clientSecret){
      setShowBuyModal(false);
    }

  }, [clientSecret]);

  useEffect(() => {
    if (selectedProduct) {
      setSelectedVariant(
        selectedProduct.store.find(
          (x) => x.keys === selectedKeys && x.valid === selectedValidity
        )
      );
    } else {
      setSelectedVariant(null);
    }
  }, [selectedKeys, selectedValidity, selectedProduct]);

  const loadCurrencyRates = async () => {
   
    const { data } = await axiosInstance.post("app/currency-rates");
    if (data.status) {
      console.log("setCurrencyRates:", data.currencyRates);
      setCurrencyRates(data.currencyRates);
    }
  };


  //stripe
  const appearance = {
    theme: "stripe",
  };
  const stripeOptions = {
    clientSecret,
    appearance,
  };

  const razorpayVerification = async (
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature
  ) => {
    console.log("razorpayVerification start");
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axiosInstance.post(
        "orders/razorpay-verification",
        { razorpay_payment_id, razorpay_order_id, razorpay_signature },
        config
      );

      console.log("razorpayVerification response:" + JSON.stringify(data));

      if (data.status) {
        setOrderCompleted(true);
        setShowBuyModal(false);
      } else {
        console.log("verify payment failed");
      }
    } catch (e) {}
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onError = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(order, razoypayKey) {
    console.log("displayRazorpay loading true");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK fail to load. Are you online?");
      return;
    }

    const options = {
      key: razoypayKey, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: order.currency,
      name: "Digi Bulk Marketing",
      description: "Order #" + order.receipt,
      image: "https://api.digibulkmarketing.com/media/reseller/dbm/logo.png",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

      prefill: {
        name: profile ? profile.name : "", //order.name,
        email: profile ? profile.email : "",
        contact: profile ? profile.phone : "",
      },

      handler: function (response) {
        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_order_id);
        console.log(response.razorpay_signature);

        razorpayVerification(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },
      theme: {
        color: "#3399cc",
      },
    };

    var razorpay = new window.Razorpay(options);

    razorpay.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    razorpay.open();
  }

  const onBuyNowSubmit = async (currency,paymentMethod) => {
    setOrderId(null);
    setClientSecret(null);

    const { data } = await axiosInstance.post("orders/buy-license-keys", {
      currency: currency,
      payment_method: currency === "INR" ? "razorpay" : "stripe",
      product_id: selectedProduct.id,
      variation_id: selectedVariant._id,
    });

    if (data.status === false) {
      swal("Buy License Keys", data.message, "error");
    } else {

      setOrderId(data.order_id);

      if (paymentMethod === "phonepe") {
        window.location.replace(data.redirect_url);
      } else if (paymentMethod === "razorpay") {
        displayRazorpay(data.result, data.razorpay_key);
      } else if (paymentMethod === "paypal") {
        swal("Buy License Keys", "Payment method not supported!", "error");
      } else if (paymentMethod === "stripe") {
        setClientSecret(data.client_secret);
      }
    }
  };

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  }

  function getProductPrice(currency) {
    if (currency === "INR") {
      return round(selectedVariant?.price);
    } else if (currency === "USD") {
      var amount = selectedVariant?.price * currencyRates["USD"];
      return round(amount + amount * 0.05);
    }
  }

  return (
    <>
      <Modal
        className="modal fade"
        show={clientSecret}
        onHide={() => setClientSecret(null)}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Stripe Payment</h4>
              <button
                type="button"
                className="close"
                onClick={() => setClientSecret(null)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  {(clientSecret && orderId) && (
                    <Elements options={stripeOptions} stripe={stripePromise}>
                      <StripeCheckout
                        clientSecret={clientSecret}
                        orderId={orderId}
                        history={history}
                        onPaymentSuccess={() => {
                          setClientSecret(null);
                          setShowBuyModal(false);
                          setOrderCompleted(true);
                        }}
                      />
                    </Elements>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                size="sm"
                type="button"
                onClick={() => setClientSecret(null)}
                variant="danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Discard
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={showBuyModal}
        onHide={setShowBuyModal}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Buy {selectedProduct?.name}</h4>
              <button
                type="button"
                className="close"
                onClick={() => setShowBuyModal(false)}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="text-center">
                    <img
                      className="img-fluid p-3 w-50"
                      //style={{height:"150px"}}
                      variant="top"
                      src={selectedProduct?.image}
                    />

                    <p>{selectedProduct?.description}</p>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Choose Keys</label>

                      <select
                        className="form-control"
                        value={selectedKeys}
                        onChange={(e) =>
                          setSelectedKeys(Number(e.target.value))
                        }
                      >
                        {removeDuplicates(
                          (selectedProduct?.store ?? []).map((x) => x.keys)
                        ).map((x, i) => (
                          <option value={x} key={"key-" + x}>
                            {x} License Keys
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-md-6">
                      <label>Choose Validity</label>

                      <select
                        className="form-control"
                        value={selectedValidity}
                        onChange={(e) =>
                          setSelectedValidity(Number(e.target.value))
                        }
                      >
                        {removeDuplicates(
                          (selectedProduct?.store ?? []).map((x) => x.valid)
                        ).map((x, i) => (
                          <option value={x} key={"key-" + x}>
                            {x} Days
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* <p>Buy {selectedVariant?.title} of {selectedProduct?.name}</p> */}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                size="sm"
                type="button"
                onClick={() => setShowBuyModal(false)}
                variant="danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Discard
              </Button>
              <Button
                size="sm"
                variant="primary"
                onClick={(e) => onBuyNowSubmit("INR","razorpay")}
              >
                Pay INR {getProductPrice("INR")}/-
              </Button>

              <Button
                size="sm"
                variant="primary"
                onClick={(e) => onBuyNowSubmit("USD","stripe")}
              >
                Pay USD {getProductPrice("USD")}/-
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={orderCompleted}
        onHide={setOrderCompleted}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Order Completed</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setOrderCompleted(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <section class="hero">
                      <div class="container">
                        <div class="hero-inner">
                          <div class="hero-copy">
                            <h1 class="hero-title mt-0">Thank You!</h1>
                            <p class="hero-paragraph">
                              Your order was completed successfully.
                              <br />
                              Your License Keys is added in your account.
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title mr-auto">Buy License Keys</h4>
          </div>

          <div className="card-body">
            <p></p>
          </div>
        </div>

        {loading || products.length === 0 ? (
          <div className="text-center mt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="row">
            {products
              .filter((x) => x.enable)
              .map((product, i) => {
                return (
                  <div className="col-md-3" id={product.id}>
                    <Card
                      className="text-center shadow"
                      style={{
                        boxShadow: "0 10px 15px rgba(0, 0, 0, 0.5);",
                        borderRadius: "15px",
                      }}
                    >
                      <Card.Img
                        className="img-fluid p-3"
                        //style={{minHeight:"300px"}}
                        variant="top"
                        src={product.image}
                      />
                      <Card.Body>
                        <Card.Title>{product.name}</Card.Title>
                        <Card.Text>{product.description ?? ""}</Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={(e) => {
                            setSelectedKeys(10);
                            setSelectedValidity(30);
                            setSelectedProduct(product);
                            setShowBuyModal(true);
                          }}
                        >
                          Buy Now
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                );
              })}
          </div>
        )}
      </Fragment>
    </>
  );
};

export default BuyLicenseKeys;
