import React, { Component, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

import { getAllProducts } from "../../../store/actions/licActions";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

//class SideBar extends Component {
const SideBar = ({ match, props, history }) => {
  const products = useSelector((state) => state.lic.products);
  const masterDetail = useSelector((state) => state.lic.masterDetail);

  const dispatch = useDispatch();

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    btn.addEventListener("click", toggleFunc);

    dispatch(getAllProducts());
  }, [dispatch]);

  function toggleFunc() {
    var aaa = document.querySelector("#main-wrapper");
    return aaa.classList.toggle("menu-toggle");
  }

  /// Path
  let fullPath = window.location.pathname;
  let path = fullPath.split("/");
  path = path[path.length - 1];

  /// Active menu
  let deshBoard = [
      "",
      // "workout-statistic",
      // "workout-plan",
      // "distance-map",
      // "diet-food-menu",
      // "personal-record",
    ],
    campaign = ["all", "available", "active", "expired"];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${path === "" ? "mm-active" : ""}`}>
            <Link to="/" className="ai-icon">
              <i className="flaticon-381-home-2"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>

          <li
            className={`${
              fullPath.includes("/license-keys/") ? "mm-active" : ""
            }`}
          >
            <Link className="ai-icon has-arrow" to="#">
              <i className="flaticon-381-key"></i>

              <span className="nav-text">License Keys</span>
            </Link>
            <ul
              className={`${
                fullPath.includes("/license-keys/") ? "mm-show" : ""
              }`}
            >
              <li>
                {products
                  .filter((x) => x.enable)
                  .map((product, i) => {
                    return (
                      <Link
                        className={`${
                          fullPath.includes("/license-keys/") &&
                          path === product.code
                            ? "mm-active"
                            : ""
                        }`}
                        to={"/license-keys/" + product.code}
                      >
                        {product.name}
                      </Link>
                    );
                  })}
              </li>
            </ul>
          </li>

          <li
            className={`${
              fullPath.includes("/trial-license-keys/") ? "mm-active" : ""
            }`}
          >
            <Link className="ai-icon has-arrow" to="#">
              <i className="flaticon-381-key"></i>

              <span className="nav-text">Trial Keys</span>
            </Link>
            <ul
              className={`${
                fullPath.includes("/trial-license-keys/") ? "mm-show" : ""
              }`}
            >
              <li>
                {products
                  .filter((x) => x.enable)
                  .map((product, i) => {
                    return (
                      <Link
                        className={`${
                          fullPath.includes("/trial-license-keys/") &&
                          path === product.code
                            ? "mm-active"
                            : ""
                        }`}
                        to={"/trial-license-keys/" + product.code}
                      >
                        {product.name}
                      </Link>
                    );
                  })}
              </li>
            </ul>
          </li>

          {/* <li className={`${path.includes("trial") ? "mm-active" : ""}`}>
              <Link to="/license-keys/trial" className="ai-icon">
                <i className="flaticon-381-key"></i>
                <span className="nav-text">Trial Keys</span>
              </Link>
            </li> */}

          <li className={`${path === "redeem-voucher" ? "mm-active" : ""}`}>
            <Link to="/redeem-voucher" className="ai-icon">
              <i className="flaticon-381-gift"></i>
              <span className="nav-text">Redeem Voucher</span>
            </Link>
          </li>

          {/* <li className={`${path === "setting" ? "mm-active" : ""}`}>
            <Link to="/setting" className="ai-icon">
              <i className="flaticon-381-settings-6"></i>
              <span className="nav-text">Rebrand Setting</span>
            </Link>
          </li> */}

          <li
            className={`${fullPath.includes("/setting/") ? "mm-active" : ""}`}
          >
            <Link className="ai-icon has-arrow" to="#">
              <i className="flaticon-381-key"></i>
              <span className="nav-text">Rebrand Setting</span>
            </Link>

            <ul
              className={`${fullPath.includes("/setting/") ? "mm-show" : ""}`}
            >
              <li>
                {products
                  .filter((x) => x.enable)
                  .map((product, i) => {
                    return (
                      <Link
                        className={`${
                          fullPath.includes("/setting/") &&
                          path === product.code
                            ? "mm-active"
                            : ""
                        }`}
                        to={"/setting/" + product.code}
                      >
                        {product.name}
                      </Link>
                    );
                  })}
              </li>
            </ul>
          </li>

          <li className={`${path === "buy-license-keys" ? "mm-active" : ""}`}>
            {masterDetail &&
            process.env.REACT_APP_NAME !== "Digi Bulk Marketing Reseller" ? (
              <a
                target="_blank"
                href={masterDetail.websiteUrl ?? process.env.REACT_APP_BUY_URL}
                className="ai-icon" rel="noreferrer"
              >
                <i className="flaticon-381-box-2"></i>
                <span className="nav-text">Buy License Keys</span>
              </a>
            ) : (
              <Link className="ai-icon" to="/buy-license-keys">
                <i className="flaticon-381-box-2"></i>
                <span className="nav-text">Buy License Keys</span>
              </Link>
            )}
          </li>

          <li className={`${path === "download" ? "mm-active" : ""}`}>
            <a
              target="_blank"
              href={
                masterDetail &&
                process.env.REACT_APP_NAME !== "Digi Bulk Marketing Reseller"
                  ? masterDetail.downloadPageUrl ??
                    process.env.REACT_APP_DOWNLOAD_URL
                  : process.env.REACT_APP_DOWNLOAD_URL
              }
              className="ai-icon" rel="noreferrer"
            >
              <i className="flaticon-381-download"></i>
              <span className="nav-text">Download Software</span>
            </a>
          </li>

          <li className={`${path === "feedback" ? "mm-active" : ""}`}>
            <Link to="/feedback" className="ai-icon">
              <i className="flaticon-381-help-1"></i>
              <span className="nav-text">Feedback</span>
            </Link>
          </li>

          {process.env.REACT_APP_SHOW_TERMS === "true" ? (
            <li>
              <a
                target={"_blank"}
                href="https://digibulkmarketing.com/terms-conditions/"
                className="ai-icon" rel="noreferrer"
              >
                <i className="flaticon-381-earth-globe-1"></i>
                <span className="nav-text">Terms And Conditions</span>
              </a>
            </li>
          ) : (
            <></>
          )}

          {process.env.REACT_APP_SHOW_TERMS === "true" ? (
            <li>
              <a
                target={"_blank"}
                href="https://digibulkmarketing.com/privacy-policy/"
                className="ai-icon" rel="noreferrer"
              >
                <i className="flaticon-381-earth-globe-1"></i>
                <span className="nav-text">Privacy Policy</span>
              </a>
            </li>
          ) : (
            <></>
          )}

          {/*  <li
                           className={`${
                              campaign.includes(path) ? "mm-active" : ""
                           }`}
                        >
                           <Link
                              className="ai-icon has-arrow"
                              to="#"
                              
                           >
                               <i className="flaticon-381-promotion"></i>
                        
                        <span className="nav-text">Campaign</span>
                              
                           </Link>
                           <ul
                              
                              className={`${
                                 campaign.includes(path) ? "mm-show" : ""
                              }`}
                           >
                              <li>
                                 <Link
                                    className={`${
                                       path === "vouchers"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/vouchers"
                                 >
                                    Vouchers
                                 </Link>

                                

                              </li>
                            
                           </ul>
                        </li> */}
        </MM>

        <div className="copyright">
          <p>
            <strong>{process.env.REACT_APP_NAME}</strong> ©All Rights Reserved
          </p>
          <p>By {process.env.REACT_APP_BY}</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
