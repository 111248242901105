import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";
import MyProfile from "./components/Dashboard/MyProfile";
import Member from "./components/Dashboard/Member";
import History from "./components/Dashboard/History";
import WalletHistory from "./components/Dashboard/WalletHistory";
import Packs from "./components/Dashboard/Packs";
import SubAgents from "./components/Dashboard/SubAgents";
import Feedback from "./components/Dashboard/Feedback";

import Vouchers from "./components/Dashboard/Vouchers";
import VoucherCodes from "./components/Dashboard/VoucherCodes";
import LicenseKeys from "./components/Dashboard/LicenseKeys";
import TrialLicenseKeys from "./components/Dashboard/TrialLicenseKeys";
import RedeemVoucher from "./components/Dashboard/RedeemVoucher";

import Setting from "./components/Dashboard/Setting";
import OrderStatus from './components/Dashboard/OrderStatus';

/// Pages

import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";

import banner from "../images/banner.png";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BuyLicenseKeys from "./components/Dashboard/BuyLicenseKeys";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  //const [masterAds, setMasterAds] = useState(process.env.REACT_APP_SHOW_TERMS === "true")

  const dispatch = useDispatch();
  const masterOffer = useSelector((state) => state.auth.masterOffer);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "my-profile", component: MyProfile },
    { url: "members", component: Member },
    { url: "history", component: History },
    { url: "wallet-history", component: WalletHistory },
    { url: "packs", component: Packs },
    { url: "sub-agents", component: SubAgents },
    { url: "feedback", component: Feedback },
    { url: "redeem-voucher", component: RedeemVoucher },
    { url: "vouchers", component: Vouchers },
    { url: "buy-license-keys", component: BuyLicenseKeys },
    { url: "setting/:product", component: Setting },
    { url: "voucher-codes/:id", component: VoucherCodes },
    { url: "license-keys/:product", component: LicenseKeys },
    { url: "trial-license-keys/:product", component: TrialLicenseKeys },

    { url: "order-status/:id", component: OrderStatus },

    /// pages
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];

  return (
    <>
      {process.env.REACT_APP_SHOW_TERMS === "true" ? (
        <Modal
          className="modal fade"
          show={masterOffer}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            dispatch({
              type: "MASTER_OFFER",
              payload: false,
            });
          }}
        >
          <div class="modal-dialog m-0" role="document">
            <div class="modal-content rounded-0 p-0">
              <button
                type="button"
                class="mi-close"
                onClick={() => {
                  dispatch({
                    type: "MASTER_OFFER",
                    payload: false,
                  });
                }}
              >
                <span aria-hidden="true">X</span>
                <span class="sr-only">Close alert</span>
              </button>
              <a
                href="https://api.whatsapp.com/send?phone=917698845235&text=I%20want%20to%20join%20as%20Master%20Reseller"
                target="_blank"
              >
                {" "}
                <img
                  className="img-fluid"
                  src="https://api.digibulkmarketing.com/media/reseller/dbm/master-offer.png"
                  style={{
                    scale: "1.01",
                  }}
                />
              </a>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            {process.env.REACT_APP_SHOW_TERMS === "true" ? (
              <a
                target="_blank"
                href="https://digibulkmarketing.com/product-category/reseller/?currency=INR"
              >
                <img
                  className="top-banner"
                  src="https://api.digibulkmarketing.com/media/reseller/dbm/banner.png"
                />
              </a>
            ) : (
              <></>
            )}

            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
