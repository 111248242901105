import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import xlsx from "xlsx";
import Moment from "moment";
import { format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
  Spinner,
} from "react-bootstrap";

import {
    getProfile,
 } from '../../../store/actions/AuthActions';

 import axiosInstance from '../../../services/AxiosInstance';


 import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
 

const StripeCheckout = ({ props,orderId,clientSecret, history,onPaymentSuccess }) => {
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  
  const paymentElementOptions = {
    layout: "tabs",
  };


  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          //setMessage("Payment succeeded!");
          onPaymentSuccess();
          // setClientSecret(null);
          // setShowBuyModal(false);
          // setOrderCompleted(true);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !orderId) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      swal("Payment Error", "Something is wrong", "error");
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          "https://api.digibulkmarketing.com/orders/stripe-confirmation",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(error.message);
    }

    setIsLoading(false);
  };

  return (
    
    <form id="payment-form" onSubmit={handleSubmit}>
    <PaymentElement
      id="payment-element"
      options={paymentElementOptions}
    />

<div className="row m-4">

  <div className="col-md-12 text-center">
  {isLoading ? (
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
      <Button
        type="submit"
        size="sm"
        variant="primary"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        Pay Now
      </Button>
    )}
  </div>


   </div>
    

    {message && <div id="payment-message">{message}</div>}
  </form>
  );
};

export default StripeCheckout;
